<template>
  <v-dialog
    :value="dialog"
    persistent
    width="50vw"
    class="pa-8"
  >
    <v-card>
        <v-card-title class="text-h4 title-break">
            {{ title }}
        </v-card-title>
        <v-card-text class="text-subtitle-1">
          {{ message }}
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn
                text
                color="error"
                @click="onDialogClose"
            >
                Close
            </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    Name: "ErrorDialog",
    props: {
        dialog: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
    },
    methods: {
        onDialogClose: function () {
            this.$emit('update:dialog', false)
        }
    },
}
</script>

<style>

</style>